import React from "react"
import { Link } from "gatsby"

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Logo from '../images/logo-lifesync-fullcolor.svg'

const Prefooter = () => (
  <div className="prefooterWrap">
    <Container className="prefooter">
        <Row>
            <Col md={3} lg={3} xl={2}>
                <Link to="/">
                    <img src={Logo} alt="LifeSync" width="156" className="logo" title="LifeSync" loading="lazy" />
                </Link>
            </Col>
            <Col md={3} lg={3} xl={2}>
                <ul className="prefooterList">
                    <li><Link to="/standardization/" activeClassName="active">Standardization</Link></li>
                    <li><Link to="/radiolucent-leadwires/" activeClassName="active">Radiolucency</Link></li>
                    <li><Link to="/contact/" activeClassName="active">Contact</Link></li>
                    <li><a href="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=188c9041-7568-4cb3-b16f-815eccc10736&ccId=19000101_000001&lang=en_US" target="_blank">Career Center</a></li>
                </ul>
            </Col>
            <Col md={3} lg={3} xl={2}>
                <ul className="prefooterList">                    
                    
                    <li><Link to="/cookie-policy/" activeClassName="active">Cookie Policy</Link></li>
                    <li><Link to="/privacy-policy/" activeClassName="active">Privacy & Legal</Link></li>
                    <li><Link to="/terms-and-conditions/" activeClassName="active">Terms & Conditions</Link></li>
                </ul>
            </Col>
            <Col md={12} lg={12} xl={6}>
                <Row>
                    <Col md={9} lg={10} xl={9}>
                        <p className="prefooteraddress">
                            <a href="tel:18003582468"><strong>1.800.358.2468</strong></a><br />
                            <a href="https://www.google.com/maps/place/11711+NW+39th+St,+Coral+Springs,+FL+33065/@26.2794808,-80.2869467,17z/data=!3m1!4b1!4m5!3m4!1s0x88d9100260d3a2a5:0x35d16db80f50b689!8m2!3d26.279476!4d-80.284758" target="_blank" rel="noopener noreferrer">11705 NW 39TH Street | Coral Springs | FL | 33065 | USA</a>
                        </p>
                    </Col>
                    <Col md={3} lg={2} xl={3}>
                        <ul className="sociallist">
                            <li>
                                <a href="https://www.linkedin.com/company/lifesyncmedical/about/" target="_blank" rel="noopener noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.07 27.07"><title>LinkedIn</title><g id="Layer_2" data-name="Layer 2"><g id="Page_03" data-name="Page 03"><path className="cls-1" d="M13.53,0A13.52,13.52,0,1,1,4,4a13.48,13.48,0,0,1,9.57-4Zm8.82,4.71A12.47,12.47,0,0,0,4.71,22.35a12.46,12.46,0,0,0,17.64,0,12.46,12.46,0,0,0,0-17.64Z"/><polygon className="cls-1" points="7.43 13.66 7.43 19.64 10.27 19.64 10.27 13.17 10.27 11.49 7.43 11.49 7.43 13.66"/><path className="cls-1" d="M19.57,14c-.18-1.55-.89-2.5-3-2.5a2.54,2.54,0,0,0-2.39,1.1h0V11.5H11.9v8.14h2.38v-4c0-1.06.21-2.09,1.51-2.09s1.41,1.22,1.41,2.17v4h2.44V15.17A8.56,8.56,0,0,0,19.57,14Z"/><path className="cls-1" d="M8.85,7.43a1.42,1.42,0,1,0,1.42,1.42A1.41,1.41,0,0,0,8.85,7.43Z"/></g></g></svg>
                                </a> &nbsp; 
                                <a href="https://twitter.com/lifesyncmed/" target="_blank" rel="noopener noreferrer" className="iconLink">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.99 48"><g data-name="Twitter"><path d="M24 0A24 24 0 117 7a24 24 0 0117-7zm15.63 8.36A22.11 22.11 0 1046.11 24a22 22 0 00-6.48-15.64z"/><path className="cls-1" d="M36.24 16.53a10.83 10.83 0 01-2.88.78 4.92 4.92 0 002.22-2.75 10.39 10.39 0 01-3.2 1.21 5.05 5.05 0 00-8.71 3.37 5.14 5.14 0 00.15 1.13 14.27 14.27 0 01-10.36-5.16 4.88 4.88 0 001.54 6.6 4.87 4.87 0 01-2.27-.61v.06a4.74 4.74 0 00.34 1.78 5 5 0 003.7 3.06 5 5 0 01-1.33.19 4.33 4.33 0 01-.95-.11 5.07 5.07 0 004.7 3.45A10.17 10.17 0 0113 31.65c-.41 0-.79 0-1.2-.07a14.53 14.53 0 007.7 2.22 14 14 0 0014-11.08 13.45 13.45 0 00.34-3v-.64a10.26 10.26 0 002.49-2.56z" fillRule="evenodd"/></g></svg>
                                </a>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
  </div>
)

export default Prefooter
