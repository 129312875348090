import React from "react"
import { Link } from 'gatsby'
import { FaPhone } from 'react-icons/fa';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Logo from '../images/lifesync-logo-white.svg'


const MainNavBar = () => (
    <Navbar collapseOnSelect expand="lg" bg="transparent" variant="dark">
        <Navbar.Brand href="/">
            <img src={Logo} alt="LifeSync" width="185" className="logo" title="LifeSync" loading="lazy" />
        </Navbar.Brand>
        <a href="tel:18003582468" className="phoneIcon"><FaPhone /></a>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
                <Link to="/cardiac/" activeClassName="active" className="nav-link">Cardiac</Link>
                <Nav.Link href="https://www.rochestersuperstore.com/" target="_blank" rel="noopener noreferrer">Neuro</Nav.Link>
                <Link to="/source/" activeClassName="active" className="nav-link">Source</Link>
                <NavDropdown title="Innovations" id="basic-nav-dropdown" renderMenuOnMount={true}>
                    <Link to="/radiolucent-leadwires/" activeClassName="active" className="dropdown-item">Radiolucent<br />ECG Leads</Link>
                    <Link to="/standardization/" activeClassName="active" className="dropdown-item">Standardization</Link>
                </NavDropdown>
                <Link to="/contact/" activeClassName="active" className="nav-link">Contact</Link>
            </Nav>
        </Navbar.Collapse>
    </Navbar>
)

export default MainNavBar
