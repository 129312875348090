import React from "react"

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

const Footer = () => (
  <footer className="footerWrap">
    <Container>
      <Row>
        <Col>
          © {new Date().getFullYear()} - <a href="https://www.lifesync.com">LifeSync</a> | All Rights Reserved.
        </Col>
      </Row>
    </Container>
  </footer>
)

export default Footer
